module.exports = {
  siteTitle: 'LambdaIota.com', // <title>
  manifestName: 'Lambda Iota',
  manifestShortName: 'LambdaIota', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/profile2.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Lambda Iota Alumni ',
  lastName: 'Association',
};

import React, { Component } from 'react';

import avatar from '../assets/img/profile2.png';
import config from '../../config';

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  closeNavbar() {
    this.setState({
      isCollapsed: true,
    });
  }

  render() {
    const { isCollapsed } = this.state;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        id="sideNav"
      >
        <a className="navbar-brand" href="/#page-top" onClick={() => this.closeNavbar()}>
          <span className="d-block d-lg-none">
            {config.siteTitle}
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src={avatar}
              alt=""
            />
          </span>
        </a>
        <button
          className={`navbar-toggler navbar-toggler-right ${isCollapsed ? 'collapsed' : ''
            }`}
          type="button"
          data-toggle="collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}
          id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#about" onClick={() => this.toggleNavbar()}>About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#reunion" onClick={() => this.toggleNavbar()}>50th Reunion</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/information" onClick={() => this.toggleNavbar()}>Update Your Information</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/dues" onClick={() => this.toggleNavbar()}>Giving!</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#connect" onClick={() => this.toggleNavbar()}>Connect on Facebook</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#contact" onClick={() => this.toggleNavbar()}>Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
